import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlicer';
import mainReducer from './mainSlicer';

const appReducer = combineReducers({
  userState: userReducer,
  mainState: mainReducer,
});

const RESET_STORE = 'RESET_STORE';

export const resetStore = () => ({
  type: RESET_STORE,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }

  // Andere Reducer-Logik hier ...

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
