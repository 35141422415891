import React from "react";


function NotFoundPage() {

    return (
        <div>
        <h1>404 Not Found</h1>
        </div>
    );
    }

export default NotFoundPage;