import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig, logOutUser } from '../firebase';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import LoginPage from '../pages/LoginPage';
import { AppBar, Toolbar, Button } from '@mui/material';

const LayoutContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;


const StyledAppBar = styled(AppBar)`
    background-color: #ffffff;
    color: #000000;
`;

const NewLayout = ({ children }) => {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);

    // Set back to false before deployment
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });
        return unsubscribe;
    }, []);

    const handleLogout = async () => {
        await logOutUser();
    };

    if (isAuthenticated) {
        return (
            <>
                <StyledAppBar position="sticky">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Assembly Mobile
                        </Typography>
                        <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    </Toolbar>
                </StyledAppBar>
                <LayoutContainer>
                    {children}
                </LayoutContainer>
            </>
        );
    } else {
        return <LoginPage />;
    }
};

export default NewLayout;
