import React from 'react';
import { Tabs, Avatar } from 'antd';
import { Container } from '@mui/system';
import Logo from '../assets/Logo.png';
import { LoginForm } from '../components/forms/LoginForm';


function LoginPage() { 

  const items = [
    {
      key: '1',
      label: 'Anmelden',
      children: <LoginForm />,
    }
  ];

    return (
      <div
        style={{
          display: 'grid',
          placeItems: 'center',
          minHeight: '100vh',
        }}
      >
        <Container sx={{ marginTop: 0 }}>
          <div>
            <Avatar
              size={128}
                src={Logo}
                style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </div>
          <Tabs defaultActiveKey="1" size="large" centered items={items} 
          />
        </Container>
      </div>
    );
};



export default LoginPage;