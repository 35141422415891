import { Card, CardContent, CardMedia, Typography, makeStyles } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";


const HomePage = () => {

    const galleryItems = [
        {
            id: 1,
            title: "Bild 1",
            imageUrl: "https://example.com/image1.jpg",
        },
        {
            id: 2,
            title: "Bild 2",
            imageUrl: "https://example.com/image2.jpg",
        },
        {
            id: 3,
            title: "Bild 3",
            imageUrl: "https://example.com/image3.jpg",
        },
    ];

    return (
        <div>
            <Typography variant="h4">
                Die mobile Version ist noch nicht verfügbar.
            </Typography>
            <Typography variant="body">
                Du kannst die Desktop Version unter folgendem Link erreichen:
                <a href="https://hpm.assembly-data.app/">https://hpm.assembly-data.app/</a> dazu musst du Assembly von deinem Computer aus aufrufen.
            </Typography>
            {/* <Grid container spacing={2}>
                {galleryItems.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.id}>
                        <Card>
                            <CardMedia
                                image={item.imageUrl}
                                title={item.title}
                            />
                            <CardContent>
                                <Typography variant="h6">{item.title}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid> */}
        </div>
    );
};

export default HomePage;