import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    firId: '',
    mongoUser: {  },
    firebaseUser: { },
    darkMode: false,
    absences: [],
    statistics: {},
}

const userSlicer = createSlice({
  name: 'firUser',
  initialState: initialState,
  reducers: {
    updateFirId: (state, action) => {
        state.firId = action.payload;
    },
    updateMongoUser: (state, action) => {
        state.mongoUser = action.payload;
    },
    updateFirebaseUser: (state, action) => {
        state.firebaseUser = action.payload;
    },
    updateDarkMode: (state, action) => {
        state.darkMode = action.payload;
    },
    updateAbsences: (state, action) => {
        state.absences = action.payload;
    },
    updateUserStatistics: (state, action) => {
        state.statistics = action.payload;
    },
  },
});

export const {
    updateFirId,
    updateMongoUser,
    updateFirebaseUser,
    updateDarkMode,
    updateAbsences,
    updateUserStatistics,
} = userSlicer.actions;

export default userSlicer.reducer;
