import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import antdTheme from './theme/antdTheme';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase';
import { resetStore } from './redux/store';
import { updateFirId } from './redux/userSlicer';
import { useDispatch } from 'react-redux';
import NewLayout from './layout/layout';

function App() {

  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  useEffect(() => {
    // Überprüfen, ob es sich um ein mobiles Gerät handelt
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    // Weiterleitung, wenn es sich nicht um ein mobiles Gerät handelt
    if (!isMobileDevice) {
      window.location.href = 'https://hpm.assembly-data.app/';
    }
  }, [])


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
      setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
        dispatch(resetStore());
      }
      try {
        dispatch(updateFirId(user.uid));
        // await updateMongoId(user.uid);
      } catch (error) {
        console.log("Error while updating firId. ", error);
      };
      // updateFirUid(user.uid);
      if (isAuthenticated) {
        // setProject(); // Hier muss noch festgelegt werden, dass nur manager alle projekte abrufen können.
          //session Initialisieren
      };
    });
    return unsubscribe;
    //eslint-disable-next-line
  }, [isAuthenticated, auth]);

  if (isAuthenticated) {
    return (
      <ConfigProvider theme={antdTheme}>
      <BrowserRouter>
        <NewLayout>
          <Routes>
            <Route path="/" element={isAuthenticated ? <HomePage /> : <LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </NewLayout>
      </BrowserRouter>
      {document.getElementById('rootContainer')}
    </ConfigProvider>
    );
  } else {
    return (
      <ConfigProvider theme={antdTheme}>
      <BrowserRouter>
       <NewLayout>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
          </Routes>
        </NewLayout>
      </BrowserRouter>
      {document.getElementById('rootContainer')}
    </ConfigProvider>
    );
  };

}

export default App;
